<template>
  <v-app class="mx-auth mx-auth-center">

    <img class="mx-auth-logo" src="/assets/images/logo-primary.png" alt="logo">
    <span class="mx-auth-version">{{ options.version }}</span>

    <v-card width="500" elevation="3" class="ma-auto px-7 py-11">
      <v-card-title class="mt-n3">
        <h2 style="font-weight: 400; line-height: 22px; font-size: 16px;" v-html="$t('auth.form.password.email.title') "></h2>
      </v-card-title>
      <v-card-text>
      </v-card-text>
      <v-card-actions class="pl-4">
        <router-link :to="{name: 'login'}">{{ $t('auth.form.password.forgot.btn.login') }}</router-link>
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      form: {
        email: '',
      },
      error: false,
      preloader: false,
      emailRules: [
          value => !!value || 'Email is required.',
      ],
      passwordRules: [
        value => !!value || 'Password is required.',
      ],
      formValidity: false
    }
  },
  methods: {
    resetValidation() {
      this.$refs.signUpForm.resetValidation()
    },
    resetForm() {
      this.$refs.signUpForm.reset()
    },

    login() {
      this.preloader = true
      this.error = false
      this.$store.dispatch('passwordForgot', this.form).then(() => {
        this.$router.replace({
          name: 'password.reset'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.error = true;
            break;
        }
        this.preloader = false
      })
    }
  }
}
</script>